import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import searchData, { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import wochenabschluesseSearchData, { WochenabschluesseSearchData } from '@/shared/model/smallPayloadModels/wochenabschluesseSearchData';
import DateField from '@/components/_common/date-field/DateField.vue';
import DateUtils from '@/shared/utils/DateUtils';


const logger = new Logger('search-form');
const authModule = namespace('auth');
const changeVoucherModule = namespace('changeVoucher');
const wochenabschluesseData = namespace('wochenabschluesse');

@Component({
  components: { DateField },
})
export default class SearchFormEndOfWeek extends Vue {
  @Ref('rechnungsnummerCombobox')
  private refRechnungsnummerCombobox!: any;
  @Ref('statusesCombobox')
  private refStatusesCombobox!: any;
  @Ref('membersCombobox')
  private refMembersCombobox!: any;

  @Ref('titlesCombobox')
  private refTitlesCombobox!: any;

  @wochenabschluesseData.Getter('getWochenabschluesseSearchParams')
  private zrWochenabschluesseSearchParams!: any;

  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroupGetter!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;

  @authModule.Getter('zrNummer')
  private zrNummer!: any;
  @authModule.Getter('zrNummerWithRoles')
  private zrNummerWithRoles!: any;

  @wochenabschluesseData.Action('getSupplierMembers')
  private actionGetSupplierMembers!: any;

  @wochenabschluesseData.Action('updateWochenabschluesseSearchData')
  private actionUpdateWochenabschluesseSearchData!: any;
  @wochenabschluesseData.Action('getTitlesMembers')
  private actionGetTitlesMembers!: any;
  @wochenabschluesseData.Getter('isWochenabschluesseSearchDataEmpty')
  private isWochenabschluesseSearchDataEmpty!: any;
  @wochenabschluesseData.Getter('resetwochenabschluesseSearchFormData')
  private actionResetwochenabschluesseSearchFormData!: any;

  
  @wochenabschluesseData.Getter('getWochenabschluesseSearchData')
  private getWochenabschluesseSearchData!: any;

  get isSingleZrNummer() {
    return !!this.zrNummer && !this.zrNummer.includes(',');
  }

  created() {    
    console.log('---created--SEARCH--onOptions FilterChanged---------------onOptions FilterChanged-----------------------------------created this.getWochenabschluesseSearchData :>> ', this.getWochenabschluesseSearchData);
    if (!this.getWochenabschluesseSearchData) {
     // console.log('------------------------created  -onOptions FilterChanged-  this.getWochenabschluesseSearchData-----------------------created this.getWochenabschluesseSearchData :>> ', this.getWochenabschluesseSearchData);
       this.getWochenabschluesseSearchData = wochenabschluesseSearchData.defaultData(); // `{}` returned  error      
    }

    //this.getWochenabschluesseSearchData.isShowAllData = true;
    // this.wochenabschluesseSearchData.isShowAllData = this.getWochenabschluesseSearchData.isShowAllData;
    //this.wochenabschluesseSearchData.datum='2024-11-11';
   // console.log('---created---after -onOptions FilterChanged---------------onOptions FilterChanged-----------------------------------created this.getWochenabschluesseSearchData :>> ', this.getWochenabschluesseSearchData, ' this.getWochenabschluesseSearchData  ', this.getWochenabschluesseSearchData);
  }
  

  async mounted() {
    var promiseAll = [];
    console.log('-------search-form-wochenabschluesse----------moun ted---------created this.getWochenabschluesseSearchData----------------------------------  ===>>>   ', this.isUserInAzureAdminGroupGetter , '    ', (!this.isUserInAzureAdminGroupGetter && !this.isSingleZrNummer));    
    promiseAll.push(this.getMembers(), this.getTitles());
  }

  isSearchDataWasCleared = false;
  @Watch('isWochenabschluesseSearchDataEmpty', { deep: true })
  public async onIsWochenabschluesseSearchDataEmpty(newVal: boolean, oldVal: boolean) {
    console.log('---------------------------is is WochenabschluesseSearchDataEmpty---------------------------------  ===>>>   ');
    logger.log('on Is WochenabschluesseSearchDataEmpty on Is WochenabschluesseSearchDataEmpty :>> ', newVal);
    if (newVal == true) {
      this.isSearchDataWasCleared = true; // avoid eternal loop on clearing `searchData` (watcher glitch without this variable)
      this.wochenabschluesseSearchData = wochenabschluesseSearchData.defaultData();
    }
  }

  @Watch('wochenabschluesseSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: WochenabschluesseSearchData, oldVal: WochenabschluesseSearchData) {
    // avoid loop when `searchData` was reset
    console.log('--SEARCH---wochenabschluesse SearchData------onOptions FilterChanged-------------------------wochenabschluesse SearchData------------------------  ===>>>   ', this.wochenabschluesseSearchData.receiverNumbers, '  wochenabschluesseSearchData.isShowAllData ', this.wochenabschluesseSearchData.isShowAllData);
    if (!this.isSearchDataWasCleared) {
      // (GSP-102) Always take into account the `zrNummer` to avoid show all records for `gsUser`
      let payload = Object.assign({}, this.wochenabschluesseSearchData);
      console.log('-----------1 onOptions FilterChanged-------------------------wochenabschluesse SearchData------------------------  ===>>>  payload ', payload);
      payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
      payload.zrNummer = this.getZrNummerDependOnRole();
      payload.receiverNumbers = this.wochenabschluesseSearchData.receiverNumbers;     
      payload.titles = this.wochenabschluesseSearchData.titles;
      payload.isShowAllData = this.wochenabschluesseSearchData.isShowAllData;
      console.log('-----------2 onOptions FilterChanged-------------------------wochenabschluesse SearchData------------------------  ===>>>  payload ', payload, ' ayload.receiverNumbers ', payload.receiverNumbers);
      this.actionUpdateWochenabschluesseSearchData(payload); // (GSP-075) use `Object.assign` to break reference for `searchData` to get new data from backend on any input clear or multiple select
    }
    this.isSearchDataWasCleared = false;
  }

  getZrNummerDependOnRole() {
    let result = this.isUserOnlyGs ? (this.zrNummerWithRoles.endOfWeekZrNummer + '') || '-' : this.zrNummer + '';
    return result;
  }

  private clearFacetFilter: boolean = false;
  @Watch('zrWochenabschluesseSearchParams', { deep: true })
  public async onOptionsFacetFilterChanged(newVal: any, oldVal: any) {
    this.clearFacetFilter = newVal.andClauseFieldsIds.length == 0;
  }


  private loadingMembers = false;
  private async getMembers() {
    let zrNummer = this.getZrNummerDependOnRole();
    console.log('--------------------------------------------------action  GetInvoiceMembers--------------------------------------  ===>>> zrNummer  ', zrNummer);
    this.actionGetSupplierMembers( {zrNummer: zrNummer, isUserInAzureAdminGroupGetter: this.isUserInAzureAdminGroupGetter} )
        .then((result: any) => {
            this.members = result;
            console.log('--------------------------------------------------action  GetInvoiceMembers--------------------------------------  ===>>> this.supplierMembers  ', this.members);
        })
        .catch((err: any) => {
            logger.error(err);
        });
  }

  private async getTitles() {
    let zrNummer = this.getZrNummerDependOnRole();
    console.log('--------------------------------------------------action  GetInvoiceMembers--------------------------------------  ===>>> zrNummer  ', zrNummer);
    this.actionGetTitlesMembers( {zrNummer: zrNummer, isUserInAzureAdminGroupGetter: this.isUserInAzureAdminGroupGetter} )
        .then((result: any) => {
            this.titles = result;
            console.log('--------------------------------------------------action  GetInvoiceMembers--------------------------------------  ===>>> this.supplierMembers  ', this.members);
        })
        .catch((err: any) => {
            logger.error(err);
        });
  }
  statuses: any = [];
  members = [];
  titles = [];
  private isShowAllData:boolean = true;
  wochenabschluesseSearchData= wochenabschluesseSearchData.defaultData();

  changeDate(value: any) {
    this.wochenabschluesseSearchData.datum = value || undefined; // new Date(value).toJSON();

    // (GSP-102) Always take into account the `zrNummer` to avoid show all records for `gsUser`
    const payload = Object.assign({}, this.wochenabschluesseSearchData);
    payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    payload.zrNummer = this.getZrNummerDependOnRole();

    this.wochenabschluesseSearchData = Object.assign({}, payload); // workaround (sometimes data change is just ignored by watcher)
  }

  //#region Filtering autocomplete data based on previous autocomplete values
  private setSearchFormFiltersPayload() {
    let newVal = this.wochenabschluesseSearchData;
    let chipDataString= this.zrWochenabschluesseSearchParams.andClauseFieldsIds.map((el: any) => `${el.chipField}:${el.searchWord}`).join(',');
    let payload: WochenabschluesseSearchData = {     
      datum: newVal.datum,
      receiverNumbers: [],
      titles:[],
      isShowAllData: this.isShowAllData,
      isUserInAzureAdminGroup: this.isUserInAzureAdminGroupGetter,
      zrNummer: this.getZrNummerDependOnRole(),
      chipDataString: chipDataString,
    };
    return payload;
  }

  showMenu() {
    this.refRechnungsnummerCombobox.activateMenu();
  }

  reloadRechnungsnummersList: boolean = false;
  reloadStatusesList: boolean = false;
  reloadMembersList: boolean = false;
  reloadSuppliersList: boolean = false;


  //#endregion

  searchRechnungsnummerInput = '';
  searchStatusInput = '';
  searchMembersInput = '';
  searchTitlesInput = '';
  searchModelNameInput = '';
  searchSupplierInput = '';

  clearSearchInput() {
    this.searchRechnungsnummerInput = '';
    this.searchStatusInput = '';
    this.searchMembersInput = '';
    this.searchTitlesInput = '';
    this.searchModelNameInput = '';
    this.searchSupplierInput = '';
  }

  clearSearchFormData() {
    this.actionResetwochenabschluesseSearchFormData();
  }

  private allowedDates(date: string) {
    const dateValue = new Date(date);
    let isFriday = dateValue.getDay() === 5;
    const today = new Date();
    let twoWeeksFromToday = new Date(today.getTime() + 12096e5 - 864e5); // `12096e5` is 14 days in milliseconds // (GSP-098) use 13 days (not 14) for 2 weeks after to be able to select again the Friday that 2 weeks from current Friday (otherwise it will be selected by default but you could not select it again manually)
    //`864e5` = 86400000 is 1 day in milliseconds

    if (this.isTodayMondayOrTuesday()) {
        twoWeeksFromToday = new Date(today.getTime() + 6048e5); // add 7 days in milliseconds to make next Friday valid (in 11 or 10 days (from Mon and Tue), not in 18 and 17 days as was before)
    }

    const isDateMoreThan2Weeks = dateValue > twoWeeksFromToday;

    return isFriday;// && isDateMoreThan2Weeks;
}

private isTodayMondayOrTuesday() {
  const today = new Date();
  const isMondayOrTuesday = today.getDay() === 1 || today.getDay() == 2;
  return isMondayOrTuesday;
}

private changeListData(value: boolean){
  console.log('-----------onOptions FilterChanged-----------change ListData--------------------------------------  ===>>> fff  ', this.wochenabschluesseSearchData.isShowAllData, '   ');
  this.wochenabschluesseSearchData.isShowAllData=value;
  this.wochenabschluesseSearchData = Object.assign({}, this.wochenabschluesseSearchData);
  console.log('-----------onOptions FilterChanged-----------change ListData--------------------------------------  ===>>> fff  ', this.wochenabschluesseSearchData.isShowAllData, '   ');
}


}
