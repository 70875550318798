export interface ZrNews {
    id: number; // uint
    bild_small: string; // string?
    title: string; // string
    text: string; // string
    created_by: string; // string
    created_on: string; // System.DateTime
    is_valid_for_supplier: boolean; // bool
    is_valid_for_member: boolean; // bool
    is_valid_for_intern: boolean; // bool
    valid_from: string; // System.DateTime
    valit_until: string; // System.DateTime?
    number_of_attachments: number; // int
    attachment_names: any; // string[]?

    news_files: Array<any>; // string[]?
    news_read_info: Array<any>; // string[]?

    // frontend fields
    news_image: any;
    news_attachment: any;

}

export interface Value {
  value: ZrNews[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ZrNews>): any {
  return {
    id: data?.id || 0,
    bild_small: data?.bild_small || undefined,
    title: data?.title || '',
    text: data?.text || '',
    created_by: data?.created_by || 'user',
    created_on: data?.created_on || new Date(),
    is_valid_for_supplier: data?.is_valid_for_supplier || false,
    is_valid_for_member: data?.is_valid_for_member || false,
    is_valid_for_intern: data?.is_valid_for_intern || false,
    valid_from: data?.valid_from || undefined,
    valit_until: data?.valit_until || undefined,
    number_of_attachments: data?.number_of_attachments || 0,
    attachment_names: data?.attachment_names || undefined,
  };
}

function parse(data?: Partial<ZrNews>): ZrNews {
  return {
    id: data?.id || 0,
    bild_small: data?.bild_small || '',
    title: data?.title || '',
    text: data?.text || '',
    created_by: data?.created_by || '',
    created_on: data?.created_on || '',
    is_valid_for_supplier: data?.is_valid_for_supplier || false,
    is_valid_for_member: data?.is_valid_for_member || false,
    is_valid_for_intern: data?.is_valid_for_intern || false,
    valid_from: data?.valid_from || '',
    valit_until: data?.valit_until || '',
    number_of_attachments: data?.number_of_attachments || 0,
    attachment_names: data?.attachment_names || '',

    news_files: data?.news_files || [],
    news_read_info: data?.news_read_info || [],
    news_image: data?.news_image || '',
    news_attachment: data?.news_attachment || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
