import { ActionTree } from 'vuex';
import { ZrNewsState } from './types';
import { RootState } from '../../types';
import { defaultBackendZrNews } from '@/shared/backend/zrNews';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import zrNews, { Value, ZrNews } from '@/shared/model/zrNews';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import DownloadUtils from '@/shared/utils/DownloadUtils';
// import { CONST } from '../utils/Constants';

const logger = new Logger('actions.ZrNews');
export const actions: ActionTree<ZrNewsState, RootState> = {
  getZrNewsList({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any, contactType?: any }) {
    commit('setZrNewsIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getZrNewsSearchParams;
    // let organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendZrNews
      .getZrNewsList(searchParams, payload?.contactType)
      .then((response: AxiosResponse<Value>) => {
        commit('setZrNews', response.data);
        commit('setZrNewsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setZrNewsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getZrNews({ commit, dispatch }, id: string) {
    return defaultBackendZrNews
      .getZrNews(id)
      .then((response: AxiosResponse<ZrNews>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateZrNews({ commit, dispatch }, file: ZrNews) {
    return defaultBackendZrNews
      .updateZrNews(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ZrNews;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.zrNews_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteZrNews({ commit, dispatch }, id: string) {
    return defaultBackendZrNews
      .deleteZrNews(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ZrNews;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  async uploadNewsDocument({ commit }, payload: any) {
    const data = new FormData();
    data.append('File', payload.document, payload.document.name);
    data.append('Id', payload.id || 3242);
    data.append('FileName', payload.FileName);
    data.append('BucketName', payload.BucketName);
    data.append('ZrNewsId', payload.ZrNewsId);
    // data.append('ParentId', payload.parentId || ''); //  Fix `undefined` error when upload from `Document-data` tab (NOT dialog)

    return await defaultBackendZrNews
      .uploadNewsDocument(data)
      .then((response) => {
        module_utils.ok(i18n.t(`success.upload_news_file`), commit, logger);
        return response.data;
      })
      .catch((e) => {
        // commit('resetDocumentFile');
        module_utils.error(i18n.t(`error.upload_news_file`), commit, e, logger);
        throw e;
      });
  },
  async getNewsFileDownloadLink({ commit }, payload: any) {
  
    try {
      return await defaultBackendZrNews
      .getNewsFileDownloadLink(payload)
      .then((response) => {
        console.log('getNewsFileDownloadLink response :>> ', response);
        let fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
        const type = response.headers['content-type'];
        // console.log('filename, type :>> ', fileName, '----' , type);
        const blob = new Blob([response.data], { type: type });
        // console.log('blob :>> ', blob);
        if(payload.IsSaveOnDisk) {
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          window.URL.revokeObjectURL(url);
          return fileName;
        }
        
        var urlCreator = URL.createObjectURL(blob)
        // module_utils.ok(i18n.t(`success.getNewsFileDownloadLink`), commit, logger);
        return urlCreator;
      })
    } catch (e) {
      // commit('resetDocumentFile');
      logger.error(e);
      module_utils.error(i18n.t(`error.getNewsFileDownloadLink`), commit, e, logger);
      throw e;
    }
  },


  async getNewsCountForUser({ commit }, payload: any) {

    return await defaultBackendZrNews
      .getNewsCountForUser(payload)
      .then((response) => {
        // module_utils.ok(i18n.t(`success.getNewsCountForUser`), commit, logger);
        commit('setUnreadNews', response.data);
        return response.data;
      })
      .catch((e) => {
        // commit('resetDocumentFile');
        module_utils.error(i18n.t(`error.getNewsCountForUser`), commit, e, logger);
        throw e;
      });
  },
  async upsertZrNewsReadInfo({ commit }, payload: any) {

    return await defaultBackendZrNews
      .upsertZrNewsReadInfo(payload)
      .then((response) => {
        // module_utils.ok(i18n.t(`success.upsertZrNewsReadInfo`), commit, logger);
        commit('setNewsInfo', response.data.result);
        if (payload.UpdateRead) {
          commit('setChangeUnreadNews', payload.is_read ? -1 : 1);
        }
        return response.data;
      })
      .catch((e) => {
        // commit('resetDocumentFile');
        module_utils.error(i18n.t(`error.upsertZrNewsReadInfo`), commit, e, logger);
        throw e;
      });
  },

  async updateUnreadNews({ commit }, value: any) { 
    commit('setChangeUnreadNews', value);
  }
};
