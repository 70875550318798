import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import zrNews, { ZrNews, Value } from '../model/zrNews';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

export type ContactType = 'member' | 'supplier' | 'intern' ; // Member | Supplier | VME-internal

const logger = new Logger('backend.ZrNews');
export interface BackendZrNews {
  getZrNews(id: string): AxiosPromise<ZrNews>;
  getZrNewsList: (searchParams: SearchParams,contactType: ContactType | undefined | '' ) => AxiosPromise<Value>;
  deleteZrNews(id: string): AxiosPromise;
  updateZrNews(ZrNews: ZrNews): AxiosPromise<any>;

  uploadNewsDocument(payload: any): AxiosPromise<any>;
  getNewsFileDownloadLink(payload: any): AxiosPromise<any>;

  getNewsCountForUser(payload: any): AxiosPromise<any>;

  upsertZrNewsReadInfo(payload: any): AxiosPromise<any>;
}

export const defaultBackendZrNews: BackendZrNews = {
  getZrNews(id: string): AxiosPromise<ZrNews> {
    let url = `${URLS.zrNews}/${id}`;
    return instance.get<ZrNews>(url);
  },
  getZrNewsList(searchParams: SearchParams, contactType: ContactType | '' = ''): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.zrNewsOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      // [`$expand=news_read_info($filter=user_id eq 'dfc4af64-9a8f-43ea-b8ab-5fb19bbbdecd' )`]
      // [`$expand=news_read_info($select=id;$filter=id eq 1)`]
      [`$expand=news_read_info,news_files`] //&$orderby=news_read_info/is_favorite
    );
    logger.log(`getZrNews${url}`);
    return instance.get<Value>(url, { headers: { contactType: contactType + '' } });
  },

  deleteZrNews(id: string): AxiosPromise {
    logger.debug('deleteZrNews');
    return instance.delete(`${URLS.zrNews}/${id}`);
  },
  updateZrNews(ZrNews: ZrNews): AxiosPromise<any> {
    logger.debug('updateZrNews');
    return instance.put<ZrNews>(`${URLS.zrNews}/update`, zrNews.toAPI(ZrNews));
  },

  uploadNewsDocument(payload: any): AxiosPromise<any> {
    console.log('zrNews uploadDocument payload :>> ', payload);
    return instance.post<any>(`${URLS.zrNews}/postDocument`, payload);
  },
  getNewsFileDownloadLink(payload: any): AxiosPromise<any> {
    console.log('getNewsFileDownloadLink payload ', payload);
    return instance.put<any>(`${URLS.zrNews}/GetFileDownloadLink`, payload, {
      responseType: 'blob',
    });
  },

  getNewsCountForUser(payload: any): AxiosPromise<any> {
    console.log('getNewsCountForUser payload ', payload);
    return instance.put<any>(`${URLS.zrNews}/CountValidAdminNews`,payload);
  },

  upsertZrNewsReadInfo(payload: any): AxiosPromise<any> {
    console.log('UpsertZrNewsReadInfo payload ', payload);
    return instance.put<any>(`${URLS.zrNews}/UpsertZrNewsReadInfo`,payload);
  },
};
