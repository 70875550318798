import wochenabschluesseObj from '@/shared/model/wochenabschluesse';
import wochenabschluesseSearchData from '@/shared/model/smallPayloadModels/wochenabschluesseSearchData';
import { WochenabschluesseState } from './types';

export const state = (): WochenabschluesseState => initialState();

export const initialState = (): WochenabschluesseState => ({
  wochenabschluesseSearchData: wochenabschluesseSearchData.defaultData(),
  initialLocalStorageSearchData: undefined,
  isWochenabschluesseFileDownload: false,
  //changeVoucherSearchData: changeVoucherSearchData.defaultData(),
  //changeVoucherAeaTyps: [],
  // changeVoucherStatusTypes: [],
  // changeVoucherIntStatusTypes: [],
  // changeVoucherMembers: [],
  // changeVoucherSuppliers: [],
  // changeVoucherMinMaxBruttoNetto: undefined,
  // initialLocalStorageChangeVoucherSearchData: undefined,
  wochenabschluesse: wochenabschluesseObj.parse({}),
  wochenabschluesses: {
    items: [],
    isLoading: undefined,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['end_of_week_date'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [  ],
      andClauseFieldsIds: [],
    },
  },
});
