import { MutationTree } from 'vuex';
import { WochenabschluesseState } from './types';
import Vue from 'vue';
import wochenabschluesse, { Wochenabschluesse, Value } from '@/shared/model/wochenabschluesse';

export const mutations: MutationTree<WochenabschluesseState> = {
  setZRWochenabschluesse(state, payload: Value) {
    state.wochenabschluesses.items = payload.value.map((x) => wochenabschluesse.parse(x));
    state.wochenabschluesses.total = payload['@odata.count'] || 0;
    state.wochenabschluesses.isLoading = false;
  },
  setWochenabschluesseSearchData(state, payload: any) {
    console.log('55 setWochenabschluesseSearchData :>> ', payload);
    state.wochenabschluesseSearchData = payload;
  },
  resetwochenabschluesseSearchFormData(state, payload: any) { 
    state.wochenabschluesseSearchData.receiverNumbers = [];
    state.wochenabschluesseSearchData.titles = [];
    state.wochenabschluesseSearchData.zrNummer = '';
    state.wochenabschluesseSearchData.datum = undefined;
  },
  setWochenabschluesseIsLoading(state, payload: boolean) {
    state.wochenabschluesses.isLoading = payload;
  },
  setIsWochenabschluesseFileDownload(state, payload: boolean) {
    console.log('---MUTATION----ACTOIN----------------------------isWochenabschluesse FileDownload----> payload---------------------------  ', payload);
    state.isWochenabschluesseFileDownload = payload;
  },
  // setChangeVoucherMembers(state, payload: any) {
  //   state.changeVoucherMembers = payload;
  // },
  // setChangeVoucherSuppliers(state, payload: any) {
  //   state.changeVoucherSuppliers = payload;
  // },
  // setChangeVoucherSearchData(state, payload: any) {
  //   state.changeVoucherSearchData = payload;
  // },
  // setInitialLocalStorageChangeVoucherSearchData(state, payload: any) {
  //   state.initialLocalStorageChangeVoucherSearchData = payload;
  // },
};
